
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcImg: require('./images/pc/banner1.jpg'),
            //             phoneImg: require('./images/mobile/banner1.jpg'),
            //             descList: [
            //                 {
            //                     descName: '电力轮式巡检机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: 'IP55',
            //                     sort: 2
            //                 }, {
            //                     descName: '0.8m/s',
            //                     sort: 3
            //                 }, {
            //                     descName: '≥8h',
            //                     sort: 4
            //                 }, {
            //                     descName: '≤40kg',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '防护等级',
            //                     sort: 6
            //                 }, {
            //                     descName: '行走速度',
            //                     sort: 7
            //                 }, {
            //                     descName: '续航时间',
            //                     sort: 8
            //                 }, {
            //                     descName: '整备质量',
            //                     sort: 9
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcImg: require('./images/pc/banner2.jpg'),
            //             phoneImg: require('./images/mobile/banner2.jpg'),
            //             descList: [
            //                 {
            //                     descName: '电力轮式巡检机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: '可用于变电站、换流站等应用场景，外壳采用防静电、防电磁场涂层，综合运用无轨导航、图像智能识别与分析、多传感器融合等技术代替人工完成设备巡检工作，针对设备外观缺陷、表计读数、开关位置、设备测温、声音采集等进行巡检，有效提高巡检效率和质量。',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcImg: require('./images/pc/banner3.jpg'),
            //             phoneImg: require('./images/mobile/banner3.jpg'),
            //             descList: [
            //                 {
            //                     descName: '应用场景',
            //                     sort: 1
            //                 }, {
            //                     descName: '电力轮式巡检机器人适用于变电站、换流站等站点的智能巡检业务场景。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcImg: require('./images/pc/banner4.jpg'),
            //             phoneImg: require('./images/mobile/banner4.jpg'),
            //             descList: [
            //                 {
            //                     descName: '无人自主巡检',
            //                     sort: 1
            //                 }, {
            //                     descName: '无需人工干预，搭载激光雷达巡检时自主建图可根据设定路线实现无人自主巡检。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcImg: require('./images/pc/banner5.jpg'),
            //             phoneImg: require('./images/mobile/banner5.jpg'),
            //             descList: [
            //                 {
            //                     descName: '智能避障功能',
            //                     sort: 1
            //                 }, {
            //                     descName: '搭载避障传感器，通过激光雷达、防碰撞传感器实现安全运行、避障及预警。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcImg: require('./images/pc/banner6.jpg'),
            //             phoneImg: require('./images/mobile/banner6.jpg'),
            //             descList: [
            //                 {
            //                     descName: '设备红外测温',
            //                     sort: 1
            //                 }, {
            //                     descName: '搭载红外热成像仪，能够对设备表面温度进行采集，对设备温度异常进行预警。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcImg: require('./images/pc/banner7.jpg'),
            //             phoneImg: require('./images/mobile/banner7.jpg'),
            //             descList: [
            //                 {
            //                     descName: '表计识别读数',
            //                     sort: 1
            //                 }, {
            //                     descName: '通过AI视觉技术读取各类仪器仪表数据，采集到的数据超过阈值则产生预警。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcImg: require('./images/pc/banner8.jpg'),
            //             phoneImg: require('./images/mobile/banner8.jpg'),
            //             descList: [
            //                 {
            //                     descName: '视频存储监控',
            //                     sort: 1
            //                 }, {
            //                     descName: '搭载高清摄像头及视频管理设备，具备直播和录像功能，可进行集中存储、回放。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcImg: require('./images/pc/banner9.jpg'),
            //             phoneImg: require('./images/mobile/banner9.jpg'),
            //             descList: [
            //                 {
            //                     descName: '局部放电监测',
            //                     sort: 1
            //                 }, {
            //                     descName: '针对电器设备的局部放电监测，可有效评估设备内部绝缘劣化程度。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcImg: require('./images/pc/banner10.jpg'),
            //             phoneImg: require('./images/mobile/banner10.jpg'),
            //             descList: [
            //                 {
            //                     descName: '电力轮式巡检机器人',
            //                     sort: 0
            //                 },
            //                 {
            //                     descName: '规格参数',
            //                     sort: 1
            //                 }, {
            //                     descName: '外形尺寸(长×宽×高)',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: '防护等级',
            //                     sort: 3
            //                 },
            //                 {
            //                     descName: '爬坡能力',
            //                     sort: 4
            //                 },
            //                 {
            //                     descName: '涉水深度',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '主导航方式',
            //                     sort: 6
            //                 },
            //                 {
            //                     descName: '整机环境温度',
            //                     sort: 7
            //                 },
            //                 {
            //                     descName: '整备质量',
            //                     sort: 8
            //                 },
            //                 {
            //                     descName: '行走速度',
            //                     sort: 9
            //                 }, {
            //                     descName: '越障能力',
            //                     sort: 10
            //                 },
            //                 {
            //                     descName: '续航时间',
            //                     sort: 11
            //                 },
            //                 {
            //                     descName: '重复导航定位精度',
            //                     sort: 12
            //                 },
            //                 {
            //                     descName: '环境相对湿度',
            //                     sort: 13
            //                 }, {
            //                     descName: '≤650mm×550mm×800mm',
            //                     sort: 14
            //                 },
            //                 {
            //                     descName: 'IP55',
            //                     sort: 15
            //                 },
            //                 {
            //                     descName: '≤15°',
            //                     sort: 16
            //                 },
            //                 {
            //                     descName: '80mm',
            //                     sort: 17
            //                 },
            //                 {
            //                     descName: '激光雷达(3D)+惯导',
            //                     sort: 18
            //                 },
            //                 {
            //                     descName: '-25°C-+60°C',
            //                     sort: 19
            //                 },
            //                 {
            //                     descName: '≤40kg',
            //                     sort: 20
            //                 },
            //                 {
            //                     descName: '0-0.8m/s,速度可调',
            //                     sort: 21
            //                 }, {
            //                     descName: '40mm',
            //                     sort: 22
            //                 },
            //                 {
            //                     descName: '≥8h',
            //                     sort: 23
            //                 },
            //                 {
            //                     descName: '≤±2cm',
            //                     sort: 24
            //                 },
            //                 {
            //                     descName: '5%-95%',
            //                     sort: 25
            //                 }],
            //              type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcImg: require('./images/pc/banner11.jpg'),
            //             phoneImg: require('./images/mobile/banner11.jpg'),
            //             descList: [
            //                 {
            //                     descName: '电力轮式巡检机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: '高效作业，提高巡检效率和质量',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 11
            //         }
            //     ],
                elmentList:[]
        }
    },
    created() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },

    }
}